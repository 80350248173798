import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import {
  AutocompleteInput,
  CreateButton,
  Datagrid,
  DataProviderContext,
  DateInput,
  DeleteButton,
  DeleteButtonProps,
  EditActionsProps,
  EditButton,
  EditButtonProps,
  Filter,
  FormDataConsumer,
  Identifier,
  List,
  ListButton,
  NumberInput,
  Record,
  ReferenceInput,
  ReferenceManyField,
  refreshSaga,
  required,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
  TranslatableInputs,
  translate,
  useAuthProvider,
  useDataProvider,
  useMutation,
  useNotify,
  useRedirect,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { Box, Button, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { useFormState } from 'react-final-form';

import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AddIcon from '@material-ui/icons/Add';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import GetAppIcon from '@material-ui/icons/GetApp';
import UndoIcon from '@material-ui/icons/Undo';

import { config } from '../../../helpers/config';
import CustomForm, { MutationTypeProps } from '../../CustomForm';
import { useDynamicOptionList } from '../../../contexts/DynamicOption';
import { Link } from 'react-router-dom';
import DeleteListItem from '../../DeleteListItem';
import { downloadFile } from '../../../helpers/transfer-file';
import { GraphQLDataProvider } from '../../../providers/GraphQLDataProvider';
import SeriesDialog from './SeriesDialog';
import { useSeriesList } from '../../../contexts/Series';

const useStyles = makeStyles((theme) => ({
  rates: {
    width: '100%',
    margin: '0.3em',
    padding: '0.5em',
  },
  deleteConceptRateButton: {
    color: theme.palette.error.main,
  },
}));

const CANCELLABLE_SITUATION = ['GENERATED', 'PAID'];
const RECTIFIABLE_SITUATION = ['GENERATED', 'PAID'];
const REFUNDABLE_SITUATION = ['PAID', 'PENDING_REFUND'];

const validateRequired = [required()];

export const Actions = ({ basePath, data }: EditActionsProps) => {
  const authProvider = useAuthProvider();
  const dataProvider = useContext(DataProviderContext);
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();
  const redirect = useRedirect();

  const [invoicePaidOpen, setInvoicePaidOpen] = useState(false);
  const [invoiceRefundOpen, setInvoiceRefundOpen] = useState(false);
  const [invoiceCancelOpen, setInvoiceCancelOpen] = useState(false);
  const [invoiceCancelSeriesList, setInvoiceCancelSeriesList] = useState<Record[]>([]);
  const [invoiceRectifyOpen, setInvoiceRectifyOpen] = useState(false);
  const [invoiceRectifySeriesList, setInvoiceRectifySeriesList] = useState<Record[]>([]);

  const handleInvoicePaidClick = () => {
    setInvoicePaidOpen(true);
  };

  const handleInvoicePaidClose = (documentSeriesId?: string, date?: string) => {
    setInvoicePaidOpen(false);
    console.log('INVOICE_PAID_CLOSE', documentSeriesId, date);
    if (date) {
      (dataProvider as GraphQLDataProvider)
        .executeQuery('invoicePaid', {
          input: {
            invoiceId: data!.id,
            paymentAt: date,
          },
        })
        .then((response) => {
          console.log('INVOICE PAID', response);
          refresh();
        })
        .catch((error) => {
          console.error('ERROR in invoicePaid', error);
          notify(error.message, {
            type: 'error',
            messageArgs: {
              allowMissing: true,
            },
          });
        });
    }
  };

  const handleInvoiceRefundClick = () => {
    setInvoiceRefundOpen(true);
  };

  const handleInvoiceRefundClose = (documentSeriesId?: string, date?: string) => {
    setInvoiceRefundOpen(false);
    console.log('INVOICE_REFUND_CLOSE', documentSeriesId, date);
    if (date) {
      (dataProvider as GraphQLDataProvider)
        .executeQuery('invoiceRefund', {
          input: {
            invoiceId: data!.id,
            paymentAt: date,
          },
        })
        .then((response) => {
          console.log('INVOICE REFUND', response);
          refresh();
        })
        .catch((error) => {
          console.error('ERROR in invoiceRefund', error);
          notify(error.message, {
            type: 'error',
            messageArgs: {
              allowMissing: true,
            },
          });
        });
    }
  };

  const handleInvoiceCancelClick = () => {
    dataProvider
      .getList('DocumentSeries', {
        pagination: { page: 1, perPage: 10000 },
        sort: { field: 'id', order: 'desc' },
        filter: { type: 'CANCELED', defaultValue: true },
      })
      .then((response: any) => {
        console.log('DocumentSeries RESPONSE', response);
        setInvoiceCancelSeriesList(response.data);
        setInvoiceCancelOpen(true);
      });
  };

  const handleInvoiceCancelClose = (documentSeriesId?: string, date?: string) => {
    setInvoiceCancelOpen(false);
    console.log('INVOICE_CANCEL_CLOSE', documentSeriesId, date);
    if (documentSeriesId && date) {
      (dataProvider as GraphQLDataProvider)
        .executeQuery('invoiceCancel', {
          input: {
            id: data!.id,
            documentSerieId: documentSeriesId,
            date,
          },
        })
        .then((response) => {
          console.log('INVOICE CANCEL', response);
          redirect('edit', '/Invoice', response.invoiceCancel.id);
        })
        .catch((error) => {
          console.error('ERROR in invoiceCancel', error);
          notify(error.message, {
            type: 'error',
            messageArgs: {
              allowMissing: true,
            },
          });
        });
    }
  };

  const handleInvoiceRectifyClick = () => {
    dataProvider
      .getList('DocumentSeries', {
        pagination: { page: 1, perPage: 10000 },
        sort: { field: 'id', order: 'desc' },
        filter: { type: 'RECTIFIED', defaultValue: true },
      })
      .then((response: any) => {
        console.log('DocumentSeries RESPONSE', response);
        setInvoiceRectifySeriesList(response.data);
        setInvoiceRectifyOpen(true);
      });
  };

  const handleInvoiceRectifyClose = (documentSeriesId?: string, date?: string) => {
    setInvoiceRectifyOpen(false);
    console.log('INVOICE_RECTIFY_CLOSE', documentSeriesId, date);
    if (documentSeriesId && date) {
      (dataProvider as GraphQLDataProvider)
        .executeQuery('invoiceRectified', {
          input: {
            id: data!.id,
            documentSerieId: documentSeriesId,
            date,
          },
        })
        .then((response) => {
          console.log('INVOICE RECTIFIED', response);
          redirect('edit', '/Invoice', response.invoiceRectified.id);
        })
        .catch((error) => {
          console.error('ERROR in invoiceRectified', error);
          notify(error.message, {
            type: 'error',
            messageArgs: {
              allowMissing: true,
            },
          });
        });
    }
  };

  const handleDownloadClick = () => {
    downloadFile(
      `${config.documentsBaseURL.INVOICE}/${data!.document.path}`,
      `${data!.format}.pdf`,
      authProvider.getAuthorizationHeader(),
    ).catch((error) => {
      notify((error as Error).message, {
        type: 'error',
      });
    });
  };

  return (
    <>
      <TopToolbar>
        <ListButton basePath={basePath} label="viatic.labels.back" icon={<ChevronLeft />} />
        <Button
          variant="text"
          color="primary"
          component="a"
          size="small"
          startIcon={<AccountBalanceIcon />}
          onClick={handleInvoicePaidClick}
          disabled={!data || data.situation !== 'GENERATED'}
        >
          {translate('viatic.labels.invoicePaid')}
        </Button>
        <Button
          variant="text"
          color="primary"
          component="a"
          size="small"
          startIcon={<UndoIcon />}
          onClick={handleInvoiceRefundClick}
          disabled={!data || !REFUNDABLE_SITUATION.includes(data.situation)}
        >
          {translate('viatic.labels.invoiceRefund')}
        </Button>
        <Button
          variant="text"
          color="primary"
          component="a"
          size="small"
          startIcon={<CancelPresentationIcon />}
          onClick={handleInvoiceCancelClick}
          disabled={!data || !CANCELLABLE_SITUATION.includes(data.situation)}
        >
          {translate('viatic.labels.invoiceCancel')}
        </Button>
        <Button
          variant="text"
          color="primary"
          component="a"
          size="small"
          startIcon={<BorderColorIcon />}
          onClick={handleInvoiceRectifyClick}
          disabled={!data || !RECTIFIABLE_SITUATION.includes(data.situation)}
        >
          {translate('viatic.labels.invoiceRectify')}
        </Button>
        <Button
          variant="text"
          color="primary"
          component="a"
          size="small"
          startIcon={<GetAppIcon />}
          onClick={handleDownloadClick}
          disabled={!data || !data.document}
        >
          PDF
        </Button>
      </TopToolbar>
      <SeriesDialog
        open={invoicePaidOpen}
        title={translate('viatic.texts.invoicePaid')}
        onClose={handleInvoicePaidClose}
      />
      <SeriesDialog
        open={invoiceRefundOpen}
        title={translate('viatic.texts.invoiceRefund')}
        onClose={handleInvoiceRefundClose}
      />
      <SeriesDialog
        open={invoiceCancelOpen}
        title={translate('viatic.texts.invoiceCancel')}
        onClose={handleInvoiceCancelClose}
        documentSeriesList={invoiceCancelSeriesList}
      />
      <SeriesDialog
        open={invoiceRectifyOpen}
        title={translate('viatic.texts.invoiceRectify')}
        onClose={handleInvoiceRectifyClose}
        documentSeriesList={invoiceRectifySeriesList}
      />
    </>
  );
};

export const InvoiceForm = ({ mutationType }: MutationTypeProps) => {
  const classes = useStyles();
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const { values } = useFormState();
  const invoiceSeriesList = useSeriesList('INVOICE');
  const statusList = useDynamicOptionList('L_STATUS');
  const paymentMethodList = useDynamicOptionList('L_PAYMENT_METHOD');
  const paymentInstrumentList = useDynamicOptionList('L_PAYMENT_INSTRUMENT');
  const situationList = useDynamicOptionList('L_INVOICE_SITUATION');

  const deleteInvoiceLine = (invoiceLineId?: Identifier) => {
    if (invoiceLineId)
      dataProvider.delete('InvoiceLine', { id: invoiceLineId }).then(() => refresh());
  };

  return (
    <>
      <Grid item container spacing={1}>
        <Grid item container xs={12} md={6}>
          <Box flexGrow={1}>
            <ReferenceInput
              label="resources.Invoice.fields.customer"
              source="customerId"
              reference="Customer"
              filterToQuery={(text: string) => ({ search: text })}
              validate={validateRequired}
            >
              <AutocompleteInput
                optionText={(r: any) =>
                  r && `${r.firstName} ${r.lastName || ''} - ${r.legalIdentifier}`
                }
                fullWidth
              />
            </ReferenceInput>
          </Box>
          {mutationType === 'create' && (
            <Button
              variant="text"
              color="primary"
              startIcon={<AddIcon />}
              size="large"
              style={{ height: '75%' }}
              component={Link}
              to={{
                pathname: '/Customer/create',
                state: {
                  redirectInfo: {
                    redirectTo: 'create',
                    basePath: '/Invoice',
                    state: { record: { ...values } },
                    fieldName: 'customerId',
                  },
                },
              }}
            >
              {translate('ra.action.create')}
            </Button>
          )}
        </Grid>
        <Grid item container xs={12} md={6}>
          <Box flexGrow={1}>
            <ReferenceInput
              label="resources.Invoice.fields.deceased"
              source="deceasedId"
              reference="Deceased"
              filterToQuery={(text: string) => ({ search: text })}
              allowEmpty={true}
            >
              <AutocompleteInput
                optionText={(r: any) => (r && r.id ? `${r.name} - ${r.deathDate}` : '')}
                allowEmpty={true}
                fullWidth
              />
            </ReferenceInput>
          </Box>
          {mutationType === 'create' && (
            <Button
              variant="text"
              color="primary"
              startIcon={<AddIcon />}
              size="large"
              style={{ height: '75%' }}
              component={Link}
              to={{
                pathname: '/Deceased/create',
                state: {
                  redirectInfo: {
                    redirectTo: 'create',
                    basePath: '/Invoice',
                    state: { record: { ...values } },
                    fieldName: 'deceasedId',
                  },
                },
              }}
            >
              {translate('ra.action.create')}
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={12} md={3}>
          <SelectInput
            source="paymentMethod"
            resource="Invoice"
            choices={paymentMethodList}
            defaultValue={paymentMethodList.find((ele) => ele.default)!.id}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectInput
            source="paymentInstrument"
            resource="Invoice"
            choices={paymentInstrumentList}
            defaultValue={paymentInstrumentList.find((ele) => ele.default)!.id}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          {mutationType === 'create' ? (
            <SelectInput
              label="resources.Budget.fields.documentSerie"
              source="documentSerieId"
              optionText={(r: any) => `${r.year} / ${r.code}`}
              choices={invoiceSeriesList}
              defaultValue={invoiceSeriesList.find((ele) => ele.defaultValue)!.id}
              fullWidth
            />
          ) : (
            <ReferenceInput
              label="resources.Invoice.fields.documentSerie"
              source="documentSerieId"
              reference="DocumentSeries"
              filter={{ type: 'INVOICE', defaultValue: true }}
              validate={validateRequired}
            >
              <SelectInput
                optionText={(r: any) => `${r.year} / ${r.code}`}
                disabled={true}
                fullWidth
              />
            </ReferenceInput>
          )}
        </Grid>
        <Grid item xs={12} md={3}>
          <DateInput source="date" resource="Invoice" fullWidth />
        </Grid>
      </Grid>
      {mutationType === 'update' && (
        <>
          <Grid item container spacing={1}>
            <Grid item xs={12} md={2}>
              <TextInput source="format" resource="Invoice" disabled={true} fullWidth />
            </Grid>
            <Grid item xs={12} md={2}>
              <NumberInput source="subtotal" resource="Invoice" disabled={true} fullWidth />
            </Grid>
            <Grid item xs={12} md={2}>
              <NumberInput source="total" resource="Invoice" disabled={true} fullWidth />
            </Grid>
            <Grid item xs={12} md={1}>
              <TextInput source="currency" resource="Invoice" disabled={true} fullWidth />
            </Grid>
            <Grid item xs={12} md={2}>
              <SelectInput
                source="situation"
                resource="Invoice"
                choices={situationList}
                defaultValue={situationList.find((ele) => ele.default)!.id}
                disabled={true}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <SelectInput
                source="status"
                resource="Invoice"
                choices={statusList}
                defaultValue={statusList.find((ele) => ele.default)!.id}
                fullWidth
              />
            </Grid>
          </Grid>
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <Grid item container spacing={1}>
                <Paper elevation={1} className={classes.rates}>
                  <Grid item container spacing={1} alignItems="center">
                    <Grid item xs={11}>
                      <Typography variant="h6">{translate('viatic.labels.lines')}</Typography>
                    </Grid>
                    <Grid item xs={1} container direction="row-reverse">
                      <Button
                        variant="text"
                        color="primary"
                        startIcon={<AddIcon />}
                        component={Link}
                        to={{
                          pathname: '/InvoiceLine/create',
                          state: {
                            record: {
                              invoiceId: formData.id,
                            },
                          },
                        }}
                      >
                        {translate('ra.action.create')}
                      </Button>
                    </Grid>
                  </Grid>
                  <ReferenceManyField
                    label="viatic.labels.lines"
                    target="invoiceId"
                    reference="InvoiceLine"
                    sort={{ field: 'number', order: 'ASC' }}
                  >
                    <Datagrid>
                      <TextField
                        source="description"
                        resource="InvoiceLine"
                        label={translate('resources.Concept.name', { smart_count: 1 })}
                      />
                      <TextField source="amountUnit" resource="InvoiceLine" />
                      <TextField source="units" resource="InvoiceLine" />
                      <TextField source="amountSubtotal" resource="InvoiceLine" />
                      <TextField source="amountTax" resource="InvoiceLine" />
                      <TextField source="amountTotal" resource="InvoiceLine" />
                      <EditButton />
                      <DeleteListItem deleteItem={deleteInvoiceLine} />
                    </Datagrid>
                  </ReferenceManyField>
                </Paper>
              </Grid>
            )}
          </FormDataConsumer>
        </>
      )}
    </>
  );
};
