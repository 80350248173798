import React, { useState } from 'react';
import {
  Datagrid,
  EditActionsProps,
  EditButton,
  FormDataConsumer,
  Identifier,
  ListButton,
  ReferenceInput,
  ReferenceManyField,
  required,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
  TranslatableInputs,
  useDataProvider,
  useRefresh,
  useTranslate,
} from 'react-admin';
import { Button, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { ChevronLeft } from '@material-ui/icons';
import { MutationTypeProps } from '../../CustomForm';
import { useDynamicOptionList } from '../../../contexts/DynamicOption';
import { Link } from 'react-router-dom';

import PeriodFilter from './PeriodFilter';
import { useCurrentPeriod } from '../../../contexts/CurrentPeriod';
import DeleteListItem from '../../DeleteListItem';

const useStyles = makeStyles(() => ({
  rates: {
    width: '100%',
    margin: '0.3em',
    padding: '0.5em',
  },
}));

const validateRequired = [required()];

export const Actions = ({ basePath }: EditActionsProps) => (
  <TopToolbar>
    <ListButton basePath={basePath} label="viatic.labels.back" icon={<ChevronLeft />} />
  </TopToolbar>
);

export const RateForm = ({ mutationType }: MutationTypeProps) => {
  const classes = useStyles();
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const currentPeriod = useCurrentPeriod();
  const statusList = useDynamicOptionList('L_STATUS');
  const languageList = useDynamicOptionList('L_LANGUAGE');

  const [localeList] = useState(languageList.map((lang) => lang.id));
  const [selectedPeriod, setSelectedPeriod] = useState(currentPeriod!.id);

  const deleteConceptRate = (conceptRateId?: Identifier) => {
    if (conceptRateId)
      dataProvider.delete('ConceptRate', { id: conceptRateId }).then(() => refresh());
  };

  return (
    <>
      <Grid item container spacing={1}>
        {mutationType === 'update' && (
          <Grid item xs={12} md={6}>
            <TextInput source="description" resource="Rate" fullWidth disabled />
          </Grid>
        )}
        <Grid item xs={12} md={4}>
          <ReferenceInput
            label="viatic.labels.parentRate"
            source="parentRateId"
            reference="Rate"
            allowEmpty={true}
            filterToQuery={(text: string) => ({ search: text })}
          >
            <SelectInput optionText="description" emptyText="viatic.texts.emptyRate" fullWidth />
          </ReferenceInput>
        </Grid>
        {mutationType === 'update' && (
          <Grid item xs={12} md={2}>
            <SelectInput
              source="status"
              resource="Rate"
              choices={statusList}
              defaultValue={statusList.find((ele) => ele.default)!.id}
              fullWidth
            />
          </Grid>
        )}
      </Grid>
      <Grid item>
        <TranslatableInputs locales={localeList}>
          <TextInput
            source="i18nDescriptions"
            resource="Rate"
            validate={validateRequired}
            fullWidth
          />
        </TranslatableInputs>
      </Grid>
      {mutationType === 'update' && (
        <FormDataConsumer>
          {({ formData, ...rest }) => (
            <Grid item container spacing={1}>
              <Paper elevation={1} className={classes.rates}>
                <Grid item container spacing={1} alignItems="center">
                  <Grid item xs={9}>
                    <Typography variant="h6">{translate('viatic.labels.rates')}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <PeriodFilter onPeriodChange={(periodId) => setSelectedPeriod(periodId)} />
                  </Grid>
                  <Grid item xs={1}>
                    <Button
                      variant="text"
                      color="primary"
                      startIcon={<AddIcon />}
                      component={Link}
                      to={{
                        pathname: '/ConceptRate/create',
                        state: { record: { rateId: formData.id, periodId: selectedPeriod } },
                      }}
                    >
                      {translate('ra.action.create')}
                    </Button>
                  </Grid>
                </Grid>
                <ReferenceManyField
                  label="viatic.labels.rates"
                  target="rateId"
                  reference="ConceptRate"
                  sort={{ field: 'concept.description', order: 'ASC' }}
                  filter={selectedPeriod ? { periodId: selectedPeriod } : {}}
                >
                  <Datagrid>
                    <TextField source="id" label="Id Concepto tarifa" />
                    <TextField
                      source="period.description"
                      label={translate('resources.Period.name', { smart_count: 1 })}
                    />
                    <TextField source="conceptId" label="Id Concepto" />
                    <TextField
                      source="concept.description"
                      label={translate('resources.Concept.name', { smart_count: 1 })}
                    />
                    <TextField source="amount" />
                    <EditButton />
                    <DeleteListItem deleteItem={deleteConceptRate} />
                  </Datagrid>
                </ReferenceManyField>
              </Paper>
            </Grid>
          )}
        </FormDataConsumer>
      )}
    </>
  );
};
